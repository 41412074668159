// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-store-tsx": () => import("./../../../src/pages/appStore.tsx" /* webpackChunkName: "component---src-pages-app-store-tsx" */),
  "component---src-pages-dieselbank-tsx": () => import("./../../../src/pages/dieselbank.tsx" /* webpackChunkName: "component---src-pages-dieselbank-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-postos-tsx": () => import("./../../../src/pages/postos.tsx" /* webpackChunkName: "component---src-pages-postos-tsx" */),
  "component---src-pages-salas-tsx": () => import("./../../../src/pages/salas.tsx" /* webpackChunkName: "component---src-pages-salas-tsx" */)
}

